<template>
  <div>
    <!--begin::Content header-->

    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Sign In</h3>
        <p class="text-muted font-weight-semi-bold">
          Enter your email and password
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div role="alert" class="alert fade alert-danger">
          <div v-if="error" class="alert-text">
            {{ error }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label="Email"
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            label="Email"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label="Password"
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group>
        
        <p v-if="error" class="alert alert-danger">{{ error }}</p>
        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a> </a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Sign In
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      date: new Date(),
      error: "",
      panelItems: JSON.parse(localStorage.getItem("PanelItems"))
    };
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      
      //update starts - by Pavan(01-10-2022)
      //this.error = "Incorrect Credentials";
      this.error = ""; 
      //update ends - by Pavan(01-10-2022)              
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        ApiService.post("/auth/login", { email, password }).then(({ data }) => {
          if (data["message"] == "Unauthorized") {
            this.error = this.panelItems.loginIncorrect;
          } else {
            this.date.setSeconds(
              this.date.getSeconds() + data["token"]["expires_in"]
            );
            localStorage.setItem("ExpiresAt", this.date.toString());
            localStorage.setItem("AccessToken", data["token"]["access_token"]);
            localStorage.setItem(
              "RefreshToken",
              data["token"]["refresh_token"]
            );
            localStorage.setItem("Authenticated", "true");
            localStorage.setItem("Username", data["usersDetail"]["name"]);
            this.$router.push({ name: "donations" });
          }
        }).catch(() => {          //update starts - by Pavan(01-10-2022)
          this.error = "Incorrect Credentials";
        });                       //update ends - by Pavan(01-10-2022)
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
